<template>
  <ion-page class="page">
    <div class="bg-transparent my-auto">
      <ion-card class="p-4">
        <div v-if="loading" class="d-flex flex-column align-items-center">
          <ion-skeleton-text animated class="mt-3" style="width: 50px; height: 50px; border-radius: 50px" />
          <ion-skeleton-text animated class="my-3" style="width: 250px; height: 18px; border-radius: 10px" />
          <ion-skeleton-text animated class="mb-2" style="width: 100%; height: 10px; border-radius: 10px" />
          <ion-skeleton-text animated class="mb-2" style="width: 100%; height: 10px; border-radius: 10px" />
        </div>
        <div v-else class="text-center">
          <ion-icon
            class=""
            style="font-size: 40px !important"
            :icon="is_email_verified ? checkmarkCircle : closeCircle"
          />
          <div class="title my-2">
            {{ is_email_verified ? 'Woohoo!! Email verified!' : 'Verification Failed' }}
          </div>
          <div class="my-3 text-color" v-if="!is_email_verified">
            This verification link has expired or is invalid. Please log in to your account to request a new one.
          </div>

          <div>
            <router-link :to="is_email_verified ? '/home' : '/login'">
              <ion-button>
                {{ is_email_verified ? 'TAKE ME HOME' : 'LOG IN' }}
              </ion-button>
            </router-link>
          </div>
        </div>
      </ion-card>
    </div>
  </ion-page>
</template>

<script lang="ts" setup>
import { emailVerification } from '@/shared/actions/users';
import { closeCircle, checkmarkCircle } from 'ionicons/icons';
import { authStore } from '@/shared/pinia-store/auth';
import logger from '@/shared/services/logger';
import { toast } from '../native';
const { hydrateUser } = authStore();

const is_email_verified = ref(false);
const loading = ref(true);

const confirmEmailVerification = async (token: any) => {
  const res = await emailVerification(token);
  is_email_verified.value = res.is_email_verified;
  if (res.message) {
    toast.show(res.message, 'nonative', 'primary');
  }
  await hydrateUser();
  loading.value = false;
};

onMounted(async () => {
  const { isAuthenticated } = authStore();
  const router = useRouter();
  const token = router.currentRoute.value.query.token;

  if (!token) {
    router.push({ path: '/home' });
  }

  if (token) {
    try {
      await confirmEmailVerification(token);
    } catch (e) {
      logger.error({
        e,
        loc: 'email-verification',
        msg: 'Error in email verification',
      } as any);
      loading.value = false;
    }
  }

  if (isAuthenticated.value) {
    await hydrateUser();
  }
});
</script>
<style lang="sass" scoped>
.title
  font-size: 25px
  font-weight: bold
  color: #214163

.dark .text-color
  color: white
</style>
